<template>
	<div class="page">
		<div class="title">
			<p>{{ lang == 6 ? '合规与廉正' : 'Compliance & Integrity' }}</p>
			<p class="samll">{{ lang == 6 ? '做正确的事，正确地做事' : 'Do right things and do things rightly' }}</p>
			<p class="yellowLine"></p>
		</div>

		<div class="content" v-if="lang == 6">
			<div>
				<p class="tit" :class="show1 ? 'noBorder' : ''" @click="show1 = !show1"><img
						:style="!show1 ? 'transform: rotate(180deg);' : ''" src="../assets/compliance/icon.png" />
					原则与行为准则
				</p>
				<div v-show="show1">
					<div class="flexBox">
						<div class="left">
							保时捷控股萨尔茨堡（以下简称为“保时捷控股”）已成立70多年，业务遍布欧洲20多个国家、南美、中国、马来西亚、新加坡等，在全球范围赢得了卓越的声誉。成为合规和廉正的典范是保时捷控股战略的基本组成部分，社会责任和高标准的道德要求使得保时捷控股成为欧洲最大的汽车经销商。
							<br />
							作为一家在全球开展业务的公司，保时捷控股的合规意味着其员工不仅遵守现行法律，国际公约和内部规章制度，而且还包括公司的价值观诸如尊重、信任和团结。以上原则都已在公司的《行为准则》中加以规定，并适用于保时捷控股的各个层面和运营的所有国家。这其中就包括保时捷控股重要的组成成员，宝爱捷（中国）汽车投资有限公司及它成立的经销商（以下统一简称为“宝爱捷（中国）”）。我们深信，合规与廉正一直以来都是我们成功之路的基石，同时也应当是未来所有决策中应遵守的基本原则。<br />
							<a target="_blank" rel="noopener"
								:download="`https://cdn.viewcenter.cn/eventplus/file/行为准则.pdf?${new Date().getTime()}`"
								:href="`https://cdn.viewcenter.cn/eventplus/file/行为准则.pdf?${new Date().getTime()}`">附件：行为准则</a>
						</div>
						<img src="../assets/compliance/1.png" />
					</div>

					<div>
						<br>
						<b>商业合作伙伴行为准则</b>
						<br>
						宝爱捷（中国）不仅要求自己的员工遵守适用的法律规定、尊重道德价值和可持续行动的原则，同时还期望我们的商业合作伙伴遵守这些标准。
						我们在《商业合作伙伴行为准则》中定义了有关商业合作伙伴之合规最为重要的基本原则。我们希望商业合作伙伴对自己的行为负责，并同意遵守《商业合作伙伴行为准则》中的要求。
						<br>
						<a target="_blank"
							:download="`https://cdn.viewcenter.cn/eventplus/file/PHS-Code-of-Conduct-CN-final%20version%20240926%20signed%203(1).pdf?${new Date().getTime()}`"
							:href="`https://cdn.viewcenter.cn/eventplus/file/PHS-Code-of-Conduct-CN-final%20version%20240926%20signed%203(1).pdf?${new Date().getTime()}`">附件：商业合作伙伴行为准则</a>
						<br>
						<a target="_blank"
							:download="`https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/%E5%95%86%E4%B8%9A%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4%E8%A1%8C%E4%B8%BA%E5%87%86%E5%88%99%E5%9F%B9%E8%AE%AD%E6%9D%90%E6%96%99.pdf?${new Date().getTime()}`"
							:href="`https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/%E5%95%86%E4%B8%9A%E5%90%88%E4%BD%9C%E4%BC%99%E4%BC%B4%E8%A1%8C%E4%B8%BA%E5%87%86%E5%88%99%E5%9F%B9%E8%AE%AD%E6%9D%90%E6%96%99.pdf?${new Date().getTime()}`">附件：商业合作伙伴行为准则培训材料</a>
					</div>
				</div>
			</div>
			<div>
				<p class="tit" :class="show2 ? 'noBorder' : ''" @click="show2 = !show2"><img
						:style="!show2 ? 'transform: rotate(180deg);' : ''" src="../assets/compliance/icon.png" />合规</p>
				<div class="flexBox" v-show="show2">
					<div class="left">
						合规意味着遵守法律法规及公司规章制度，以及确保法律法规及公司规章制度得以贯彻执行。合规不仅仅是管理层的事情，而是所有员工的责任。合规涉及的主要方面包括反垄断、利益冲突、反洗钱、数据保护、与产品缺陷相关的法律责任、欺诈、反腐败、内幕交易等。其中，反腐败是合规工作的重中之重。
						<br />
						腐败并非微不足道的小事，它损害的是公司和员工的利益。保时捷控股高度重视腐败问题，并希望能够不断提高对打击腐败的认识。宝爱捷（中国）严厉打击腐败，坚持贯彻反腐败精神，并制定了相应的反腐败政策及举报制度。任何人，包括一般雇员，高管，合作伙伴等，均可通过举报制度提供的举报途径举报潜在的腐败问题。
						<br />
						除了反腐败外，宝爱捷（中国）还致力于打造反垄断、反洗钱等的合规环境。我们深知，公平的市场竞争环境于社会、公司、行业和消费都有益处，因此我们努力使自身行为符合国家法律法规和政策的有关规定，并在公司内发布实施了相应的反垄断和反洗钱的合规政策，努力协助创造一个公平透明的市场竞争环境。
					</div>
					<img src="../assets/compliance/2.png" />
				</div>
			</div>
			<div>
				<p class="tit" :class="show3 ? 'noBorder' : ''" @click="show3 = !show3"><img
						:style="!show3 ? 'transform: rotate(180deg);' : ''" src="../assets/compliance/icon.png" />廉正</p>
				<div class="flexBox" v-show="show3">
					<div class="left">
						廉正意味着以负责而坚定的信念行事。只有公司所有员工廉正行事，公司才会拥有廉正文化。因此，宝爱捷（中国）致力于通过采取广泛的措施和活动，建立强有力和真诚的廉正文化，廉正体现了我们对规则、准则和价值观的内在态度。宝爱捷（中国）的每一位成员，不管是管理层还是普通员工，都应当践行廉正的价值观。
					</div>
					<img src="../assets/compliance/3.png" />
				</div>
			</div>
			<div>
				<p class="tit" :class="show4 ? 'noBorder' : ''" @click="show4 = !show4"><img
						:style="!show4 ? 'transform: rotate(180deg);' : ''" src="../assets/compliance/icon.png" />举报制度
				</p>
				<div v-show="show4">
					<div class="flexBox">
						<div class="left">
							<b class="red">1.&nbsp;&nbsp;我们的举报制度</b><br>
							遵守法律法规和内部规定，以及我们的行为准则和商业合作伙伴行为准则中规定的原则，一直以来都是宝爱捷（中国）工作的重中之重。廉正与合规是我们公司成功的基石。为了达到这些标准，了解员工或供应商潜在的不当行为并加以制止是很重要的。因此，我们委托宝爱捷（中国）调查办公室代表我们运行一个独立、公正和保密的举报系统。<br>
							程序公平原则是举报制度的关键支柱之一。它保证向举报人、被举报人和协助调查的雇员提供最大限度的保护。<br>
							举报制度也包括提供匿名举报和交流的机会。我们保证不采取任何措施来识别不滥用我们举报制度的匿名举报人。对举报人和所有在宝爱捷（中国）参与调查的人员的报复是不能容忍的。在违规行为被证实之前，被举报人适用无罪推定原则，调查将以最大的保密性进行。信息将通过公平、快速和受保护的方式进行处理。<br><br>
						</div>
						<div class="right">
							<img src="../assets/compliance/4.png" />
						</div>
					</div>
					<div>
						<b>我们如何处理您的举报？</b><br>
						调查办公室有资质且经验丰富的同事将对大众集团和宝爱捷（中国）员工潜在不当行为的每一份举报进行彻底审查，并对其进行系统性跟踪。首先，您将会收到一份确认函，确认举报已被收到。调查办公室将对您的举报进行评估，包括但不限于从举报人处收集事实证据。只有在初步评估后表明有合理怀疑存在违规行为时，才会由专门的调查单位开展调查。之后，调查办公室将评估调查结果，并建议采取适当措施。有关调查状态*和结果的信息将及时提供给您。<br>
						供应商（包括直接和间接供应商）可能违反《商业合作伙伴行为准则》的行为，包括在人权和环境领域存在严重风险以及违规的行为，也可以向调查办公室举报，其中也包含需要立即采取相应行动的举报。调查办公室将通知负责部门处理相关问题，包括采取必要措施，尽量减少或消除违规行为和/或相关风险。<br>
						<b class="tip">*处理时间因案而异。</b><br>
						<!-- <a class="bgline" target="_blank"
							href="http://volkswagen-group.com">您可点击这里，获取关于大众汽车集团投诉机制程序性原则的更多信息。</a> -->
						<b class="red">2.&nbsp;&nbsp;您是否对宝爱捷（中国）的产品或服务有任何担忧或反馈？</b><br>
						如果您对您的新车或二手车有任何疑问，或对宝爱捷（中国）提供的服务有任何反馈或投诉，请联系我们的经销商。<b style="cursor: pointer;" class="tip"
							@click="popshow = true">经销商位置 -
							宝爱捷（中国）汽车投资有限公司 (paig.com.cn) </b>我们恳请您理解，举报系统无法处理客户投诉。<br>
						<b class="red">3.&nbsp;&nbsp;向我们举报</b><br>
						举报制度为举报潜在的员工不当行为提供了多种渠道，使公司可在必要时迅速审查并做出反应。<br>
						并且，这些渠道并不影响与指定机构联系的法定权利。<br>
						<b>本地举报途径：</b>
						<br>
						宝爱捷（中国）调查办公室
						<br>
						电邮：<b class="tip">compliance@paig.com.cn</b>
						<br>
						热线：+86-571 2827 2702
						<!-- （中文和英文） -->
						<br>
						<span style="visibility: hidden;">热线：</span>+86-571 2882 1125
						<!-- （中文和英文） -->
						<br>
						邮寄地址：浙江省杭州市滨江区西兴街道西兴路2333号星澜大厦4幢19层
						<br>
						邮编：310051
						<br>


						<!-- <b>总部举报途径：</b><br>
						<b class="tip">24/7热线电话</b> <br>
						您可拨打以下热线电话进行举报：<br>
						国际免费电话：+800 444 46300*<br>
						如您所在的地区没有开通免费电话渠道，您可拨打以下收费电话：+49 5361 946300<br>
						*根据您拨打的国家/地区，国际免费热线可能不可用，因为某些电话网络提供商不支持该服务。如果是该种情况，请使用前述提供的收费号码或您所在国家的特定号码。<br>
						<div class="table">
							<div>
								<span>国家</span>
								<span>免费电话</span>
								<span>本地电话</span>
							</div>
							<div>
								<span>巴西</span>
								<span>0800-5912743</span>
								<span>021-23911381</span>
							</div>
							<div>
								<span>墨西哥</span>
								<span>001-800-4610242</span>
								<span>0155-71000355</span>
							</div>
							<div>
								<span>斯洛伐克共和国</span>
								<span>0800-002576</span>
								<span>02-33325602</span>
							</div>
							<div>
								<span>美国</span>
								<span>833-6571574</span>
								<span>908-2198092</span>
							</div>
							<div>
								<span>南非</span>
								<span>0800-994983</span>
								<span>021-1003533</span>
							</div>
							<div>
								<span>马来西亚</span>
								<span>1-800-819523</span>
								<span>0154-600099</span>
							</div>
							<div>
								<span>阿根廷</span>
								<span>0800-6662992</span>
								<span>011-52528632</span>
							</div>
							<div>
								<span>德国</span>
								<span>0800 444 46300</span>
								<span>05361-946300</span>
							</div>
						</div>
						<br>
						<b>在线举报渠道：</b><br>
						您还可选择通过BKMS在线系统以任意语言与调查办公室取得联系。<a target="_blank"
							href="https://www.bkms-system.com/bkwebanon/report/clientInfo?cin=22vwgroup16&c=cn&language=chi"
							class="tip">引言
							（bkms-system.com）</a>
						BKMS系统是一个具有保密保护功能的在线举报平台。您可使用任何语言在本平台提交信息，即使该平台没有显示您所使用语言的选项。同时，您也可使用任何语言与调查办公室通过电邮或普通邮件的方式取得联系。<br>
						<b>监察员（Ombudspersons）：</b><br>
						大众集团已任命外部律师担任监察员。他们就举报制度提供意见，或在需要时确保举报人的报告以匿名方式转交给调查办公室。<br>
						您可登录以下网站获取监察员的联系信息：<a target="_blank" href="http://www.ombudsmen-of-volkswagen.com/"
							class="tip">http://www.ombudsmen-of-volkswagen.com/</a> <br>
						请注意，中国法律法规限制或禁止从中国大陆跨境传输敏感信息，包括个人信息、重要数据及中国国家机密。由于BKMS服务器和外部监察员位于境外，向BKMS或外部监察员提供此类信息可能违反适用的中国法律法规，且可能需承担被中国政府部门处罚的风险。<br>
						<b>电子邮件：</b><br>
						您可以通过电子邮件与总部调查办公室联系：<b class="tip">io@volkswagen.de</b><br>
						<b>邮寄地址/亲临总部调查办公室：</b><br>
						Volkswagen AG, Central Investigation Office<br>
						Mailbox 1717<br>
						Berliner Ring 2<br>
						38436 Wolfsburg, Germany<br>
						<b>亲自拜访：</b><br>
						请提前预约，写电子邮件到：<b class="tip">io@volkswagen.de</b> <br> -->




						关于举报系统的所有事宜，您还可以通过电子邮件联系萨尔茨堡保时捷控股公司的合规部门：<span class="red">hinweis@porsche.co.at</span>。<br>
						<b class="red">4.&nbsp;&nbsp;其他问题：</b><br>
						有关举报制度的问题或改善建议，亦可向调查办公室提出。<br>
						此外，涉及到举报制度的所有事项也可以通过 <span class="red">compliance@paig.com.cn</span> 联系我们的本地合规部门。<br>
					</div>
				</div>
			</div>
			<!-- <div>
				<b class="tip">* 请访问萨尔茨堡保时捷控股官网获取更多信息：<a target="_blank"
						href="https://www.porsche-holding.com/en/company/compliance-integrity/principles"
						class="tip">https://www.porsche-holding.com/en/company/compliance-integrity/principles</a></b>
			</div> -->
		</div>


		<div class="content" v-else>
			<div>
				<p class="tit" :class="show1 ? 'noBorder' : ''" @click="show1 = !show1"><img
						:style="!show1 ? 'transform: rotate(180deg);' : ''" src="../assets/compliance/icon.png" />
					Principle
					and Code of Conduct</p>
				<div v-show="show1">
					<div class="flexBox">
						<div class="left">
							Porsche Holding Salzburg (hereinafter referred to as Porsche Holding) has worked to achieve
							its
							outstanding reputation during the 70 years of its existence in over 20 European countries,
							in
							China, South America, Malaysia, Singapore, etc. Social responsibility and a high standard of
							ethics have made Porsche Holding what it is today: the largest automotive distributor in
							Europe.
							<br />
							As a company with operations around the globe, to Porsche Holding compliance means that its
							employees not only abide by current law but also adhere to a set of moral principles that go
							beyond merely observing the law. These principles have been set down in the company's own
							Code
							of Conduct. Naturally they apply to all levels of the Group and to all countries where
							Porsche
							Holding operates. This includes the important member of Porsche Holding, PAIG (China)
							Automobile
							Investment Co., Ltd. and its affiliated dealers (hereinafter jointly referred to as "PAIG
							(China)"). PAIG (China) firmly believes that compliance and integrity have always been the
							cornerstone of our road to success, and should also be the basic principles to be observed
							in
							all future decisions.<br />
							<a target="_blank"
								:href="`https://cdn.viewcenter.cn/eventplus/file/Code%20of%20Conduct.pdf?${new Date().getTime()}`">
								Attachment:&nbsp;Code of Conduct</a>
						</div>
						<img src="../assets/compliance/1EN.png" />
					</div>
					<div>
						<br />
						<b>Code of Conduct for Business Partners</b>
						<br>
						PAIG (China) expects compliance with applicable laws, respect for ethical values and sustainable
						action not only from its own employees, but also from its business partners. With this Code of
						Conduct for Business Partners, PAIG (China) defines the most essential principles to ensure
						compliance by business partners. We expect business partners to be responsible for their actions
						and agree to comply with the requirements of the Code of Conduct for Business Partners.
						<br>
						<a target="_blank"
							:href="`https://cdn.viewcenter.cn/eventplus/file/PHS-Code-of-Conduct-CN-final%20version%20240926%20signed%203(1).pdf?${new Date().getTime()}`">Attachment:
							Code of Conduct for Business Partners</a>
						<br>
						<a target="_blank"
							:href="`https://viewcenter.oss-cn-zhangjiakou.aliyuncs.com/eventplus/file/Training%20Material%20for%20Code%20of%20Conduct%20for%20Business%20Partners.pdf?${new Date().getTime()}`">Attachment:
							Training Material for Code of Conduct for Business Partners</a>
					</div>
				</div>
			</div>
			<div>
				<p class="tit" :class="show2 ? 'noBorder' : ''" @click="show2 = !show2"><img
						:style="!show2 ? 'transform: rotate(180deg);' : ''"
						src="../assets/compliance/icon.png" />Compliance
				</p>
				<div class="flexBox" v-show="show2">
					<div class="left">
						Compliance means complying with laws, regulations, internal rules and policies, as well as
						ensuring implementation of aforesaid. Compliance is not just a matter for management, but the
						responsibility of all employees. Key topics of compliance include anti-trust law, conflict of
						interest, money laundering, data protection, product liability, fraud by employees, corruption,
						securities law/insider trading, etc. Among others, anti-corruption is the top priority in the
						integrity work.
						<br />
						Corruption is not a trivial matter as it damages the interests of the company and employees.
						Porsche Holding attaches great importance to the issue of corruption and hopes to continuously
						improve its understanding of fighting against corruption. PAIG (China) adheres to the attitude
						of
						Porsche Holding to crack down on corruption, upholds anti-corruption spirit, and has formulated
						corresponding anti-corruption policies and reporting systems. Any person, including ordinary
						employees, senior executives, partners, can report potential corruption through the reporting
						channels provided by the reporting system.
						<br />
						In addition to anti-corruption, PAIG (China) is also committed to creating an anti-trust and
						anti-money laundering compliance environment. We are fully aware that a fair market competition
						environment is beneficial to society, companies, industries and consumption. Therefore, we
						strive to make our own behavior conform to the relevant laws, regulations and policies. We have
						also promulgated and implemented corresponding anti-trust and anti-money laundering compliance
						policies within the Group, and are trying to help create a fair and transparent market
						competition environment.
					</div>
					<img src="../assets/compliance/2EN.png" />
				</div>
			</div>
			<div>
				<p class="tit" :class="show3 ? 'noBorder' : ''" @click="show3 = !show3"><img
						:style="!show3 ? 'transform: rotate(180deg);' : ''"
						src="../assets/compliance/icon.png" />Integrity
				</p>
				<div class="flexBox" v-show="show3">
					<div class="left">
						Integrity means acting out of conviction with responsibility and steadfastness. A company can
						only have integrity when its people conduct themselves with integrity. Thus PAIG (China) is
						committed to fostering a strong and sincere culture of integrity through wide-ranging measures
						and activities. Integrity describes our inner attitude towards regulations, guidelines and
						values. Such values of integrity apply to every one of us, regardless of our function, division,
						role and task.
					</div>
					<img src="../assets/compliance/3EN.png" />
				</div>
			</div>
			<div>
				<p class="tit" :class="show4 ? 'noBorder' : ''" @click="show4 = !show4"><img
						:style="!show4 ? 'transform: rotate(180deg);' : ''"
						src="../assets/compliance/icon.png" />Whistleblower System
				</p>
				<div v-show="show4">
					<div class="flexBox" style="align-items: center;">
						<div class="left">
							<b class="red">1.&nbsp;&nbsp;Our Whistleblower System</b><br>
							Complying with statutory regulations and internal rules, and the principles laid down in our
							Code of Conduct and the Code of Conduct for Business Partners, has top priority at PAIG
							(China).
							The success of our company is based on Integrity and Compliance. To meet these standards, it
							is
							important to learn of potential employee or supplier misconduct and to put a stop to it.
							Therefore, we entrusted PAIG (China) Investigation Office to operate an independent,
							impartial and
							confidential Whistleblower System on our behalf.<br>
							A key pillar of our Whistleblower System is the principle of procedural fairness. It also
							guarantees the greatest possible protection for whistleblowers, Persons Implicated and
							employees
							contributing to the investigation of reported misconduct. <br>
							This also includes offering opportunities for anonymous reporting and communication. We
							assure
							not to perform any steps in order to identify anonymous whistleblowers, who do not misuse
							our
							Whistleblower System. Retaliation of whistleblowers and all persons who contribute to
							investigations at PAIG (China) will not be tolerated. Persons implicated are presumed
							innocent
							until the violation is proven. Investigations will be conducted with the utmost
							confidentiality.
							The information will be processed in a fair, fast and protected process.
						</div>
						<div class="right">
							<img src="../assets/compliance/4EN.png" />
						</div>
					</div>
					<div style="text-align: justify;
      text-align-last: left;">
						<br>
						<b>How do we process your report?</b><br>
						The qualified and experienced colleagues at the Investigation Office examine every report for
						potential misconduct by a Volkswagen Group and PAIG (China) employee thoroughly and follow it up
						systematically. First, you will get a confirmation of receipt. The Investigation Office then
						assesses your report. This includes gathering facts particularly from the whistleblower. Only if
						this initial evaluation shows grounds for suspicion of a violation an investigation by a
						dedicated Investigating Unit will be started. Afterwards, the results of the investigation will
						be assessed by the Investigation Office and appropriate measures will be recommended.
						Information about the status* and the outcome of the procedure will be given to you without
						undue delay.<br>
						Potential violations of the Code of Conduct for Business Partners by suppliers, including
						serious risks and violations of human rights and environment by direct and indirect suppliers,
						can also be reported to the Investigation Office-as well as reports requiring otherwise
						immediate action. The Investigation Office will inform the responsible departments, who will
						process the issue accordingly. This particularly includes taking the necessary measures to
						minimize or end violations and/or risks.<br>
						<b class="tip"> * The processing time varies depending on the subject of the procedure.</b><br>
						<!-- <a class="bgline" href="http://volkswagen-group.com" target="_blank">Find more information on
							the
							procedural principles of the Volkswagen Group
							Complaints Procedure here.</a> -->
						<b class="red">2.&nbsp;&nbsp;Do you have any concern or feedback regarding a PAIG (China)
							product or
							service?</b><br>
						If you have any questions or inquiries regarding your new or used vehicle/feedback or complaints
						about services provided by PAIG (China), please contact our dealers. <b style="cursor: pointer;"
							class="tip" @click="popshow = true">[Locations -
							PAIG (China) Automobile Investment]. </b> We kindly ask for your understanding that the
						Whistleblower System unfortunately cannot process customer complaints.
						<br>
						<b class="red">3.&nbsp;&nbsp;Making a report to our Whistleblower System</b><br>
						The Whistleblower System offers various channels to report potential employee misconduct that
						allow a swift review and reaction by our company if necessary.<br>
						However, this does not affect the statutory right to contact designated authorities.<br>
						<b>Local Hints Intake Channels:</b>
						<br>
						PAIG (China) Investigation Office
						<br>
						Email: <b class="tip">compliance@paig.com.cn</b>
						<br>
						Hotline: +86-571 2827 2702
						<br>
						<span style="visibility: hidden;">Hotline:</span> +86-571 2882 1125
						<br>
						address: 2333 Xixing Road Sei Ran Ri, Building No. 4, 19F Binjiang District Hangzhou P. R. China
						<br />
						Post: 310051
						<!-- Post:<br>2333 Xixing Road<br>
						Sei Ran Ri, Building No. 4, 19F<br>
						Binjiang District<br>
						310051 Hangzhou<br>
						P. R. China -->
						<br>



						<!-- <b>Group Hints Intake Channels</b><br>
						<b class="tip">24/7 Hotline</b> <br>
						You can make a report at 365 days, 24 hours, using the international toll-free number: <br>
						+800 444 46300*.<br>
						If your local telephone provider does not support the toll-free service, you can call the
						following chargeable number:<br>
						+49 5361 946300 <br>
						*Depending on the country you are calling from it is possible that the international toll-free
						hotline is not available since some telephone network providers do not support the service. If
						so, please use the offered chargeable number or your country specific number.
						<br>
						<div class="table">
							<div>
								<span>Country</span>
								<span>Toll Free Number </span>
								<span>Local Number</span>
							</div>
							<div>
								<span>Brazi</span>
								<span>0800-5912743</span>
								<span>021-23911381</span>
							</div>
							<div>
								<span>Mexico</span>
								<span>001-800-4610242</span>
								<span>0155-71000355</span>
							</div>
							<div>
								<span>Slovak Republic</span>
								<span>0800-002576</span>
								<span>02-33325602</span>
							</div>
							<div>
								<span>USA</span>
								<span>833-6571574</span>
								<span>908-2198092</span>
							</div>
							<div>
								<span>South Africa</span>
								<span>0800-994983</span>
								<span>021-1003533</span>
							</div>
							<div>
								<span>Malaysia</span>
								<span>1-800-819523</span>
								<span>0154-600099</span>
							</div>
							<div>
								<span>Argentina</span>
								<span>0800-6662992</span>
								<span>011-52528632</span>
							</div>
							<div>
								<span>Germany</span>
								<span>0800 444 46300</span>
								<span>05361-946300</span>
							</div>
						</div>
						<br>
						<b>Online Reporting Channel:</b><br>
						You have the option of using a web-based communication platform <a target="_blank"
							href="https://www.bkms-system.com/bkwebanon/report/clientInfo?cin=22vwgroup16&c=cn&language=eng"
							class="tip">Introduction (bkms-system.com)</a> to contact
						the Investigation Office in many languages. This system is confidential and technically secured.
						<br>
						Even if your preferred language is not offered in the reporting channel, you can use any
						language to submit your report. You can also contact the Investigation Office in any language
						via e-mail or mail.
						<br>
						<b>Ombudspersons:</b><br>
						Volkswagen Group has appointed external lawyers to act as Ombudspersons. They advise on the
						Whistleblower System or ensure that reports from whistleblowers are forwarded anonymously to the
						Investigation Office if desired.<br>
						If you want to get in contact with the Ombudsperson you can find their contact details here: <a
							target="_blank" href="http://www.ombudsmen-of-volkswagen.com/"
							class="tip">http://www.ombudsmen-of-volkswagen.com/</a> <br>
						*Please note that Chinese laws and regulations limits or prohibits cross-border
						transfer from mainland China of certain Sensitive Information, including Personal
						Information, Important Data and Chinese State Secrets. Because the BKMS-Servers and
						Ombudspersons locate overseas, by submitting such information via the BKMS or Ombudspersons
						you might risk violating the applicable Chinese laws and regulations and may also bear the
						risk of being penalized by the competent Chinese authorities.
						<br>
						<b>E-mail: </b><br>
						You can get in contact with the Investigation Office of Volkswagen Group via e-mail: <b
							class="tip">io@volkswagen.de</b><br>
						<b>Postal adress:</b><br>
						Volkswagen AG,Central Investigation Office<br>
						Mailbox 1717<br>
						Berliner Ring 2<br>
						38436 Wolfsburg, Germany<br>
						<b>In person: </b><br>
						Please make an appointment in advance writing an E-mail to<b class="tip"> io@volkswagen.de</b>
						<br> -->

						For all matters of the Whistleblower System, you can also get in contact with the Compliance
						Department of Porsche Holding Salzburg via e-mail: <span class="red">hinweis@porsche.co.at</span>.<br>
						<b class="red"> 4.&nbsp;&nbsp;Do you have further questions?</b><br>
						Questions or suggestions for improvement concerning the Whistleblower System can also be
						addressed to the Investigation Office.<br>
						Furthermore, our local Compliance Department can also be addressed in all matters of the
							Whistleblower System via <span class="red">compliance@paig.com.cn</span>.<br>
					</div>
				</div>
			</div>
			<!-- <div>
				<b class="tip">*Please visit the webpage of Porsche Holding Salzburg for more information: <a
						target="_blank"
						href="https://www.porsche-holding.com/en/company/compliance-integrity/principles"
						class="tip">https://www.porsche-holding.com/en/company/compliance-integrity/principles</a></b>
			</div> -->
		</div>
		<el-dialog :title="lang == 6 ? '请选择品牌' : 'Please select'" :visible.sync="popshow" width="30%" center>
			<div class="popcon">
				<div v-for="(item, index) in brandList" :key="index"
					@click="$router.push(`/brand/${item.id}?id=dealer`)">
					{{ item.title }}
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import axios from 'axios'
	import mixinLang from '../components/mixin.js'
	export default {
		mixins: [mixinLang],
		data() {
			return {
				show1: false,
				show2: false,
				show3: false,
				show4: false,
				popshow: false,
				brandList: []
			}
		},
		beforeDestroy() {
			this.popshow = false
		},
		methods: {
			getInfo() {
				// 品牌列表
				this.$api.getBrandList({
					cate_no: 'b4766587',
				}, {
					page: 1,
					limit: 100
				}).then(res => {
					this.brandList = res.list
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		position: relative;
		padding: 170px 0 80px;
		font-size: 18px;
	}

	.title {
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 40px;
		font-weight: bold;
		color: #FFF;
		padding: 0 111px 0;
		text-transform: uppercase;

		.samll {
			font-size: 28px;
			font-family: SourceHanSansCN-Normal, sans-serif;
			font-weight: 350;
			margin-top: 15px;
		}
	}

	.table {
		line-height: 46px;
		width: 800px;
		// text-align: center;

		>div {
			span {
				display: inline-block;
				width: calc(100%/3);
			}
		}

		>div:nth-child(1) {
			font-size: 22px;
			font-weight: bold;
		}

		>div:nth-child(odd) {
			background: rgba(181, 161, 145, 0.5);
		}

		>div:nth-child(even) {
			background: rgba(181, 161, 145, 0.3);
		}
	}

	.yellowLine {
		margin: 50px 0 40px;
	}

	.content {
		margin: 0 60px;
		background-color: #EEEEEE;
		border-radius: 16px;
		padding: 56px 51px 50px;

		// padding: 0px 111px 111px;
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 20px;
		font-weight: 350;
		line-height: 36px;
		line-height: 46px;

		.tit {
			font-family: SourceHanSansCN-Bold, sans-serif;
			font-size: 32px;
			font-weight: bold;
			color: #97233F;
			margin-bottom: 10px;
			text-transform: uppercase;
			cursor: pointer;
			position: relative;
			padding: 30px 0;

			&::after {
				content: '';
				position: absolute;
				width: 100%;
				bottom: 0;
				left: 0;
				height: 1px;
				background: linear-gradient(270deg, rgba(83, 99, 129, 0.00) 0%, rgba(83, 99, 129, 0.20) 100%);
			}

			img {
				vertical-align: middle;
				width: 19px;
				margin-right: 14px;
			}
		}

		a {
			color: #B5A191;
			text-decoration: none;
		}

		.red {
			color: #97233F !important;
			font-size: 22px;
			font-weight: bold;
			margin: 28px 0 13px;
			display: inline-block;
		}

		.flexBox {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;

			.left {
				width: 1237px;
				text-align: justify;
				text-align-last: left;

			}

			.right {
				width: 350px;
			}

			img {
				width: 350px;
				height: 350px;
				border-radius: 16px;
				margin-top: 10px;
			}
		}
	}

	.bottom {
		.textFlex {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			margin: 10px 0 50px;

			.right {
				width: 813px;
			}

			.left {
				width: 750px;
			}
		}
	}

	.tip {
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 20px;
		font-weight: 350;
		color: #97233F !important;
		margin-top: 20px;
	}

	a {
		text-decoration: none;
	}

	.noBorder::after {
		height: 0 !important;
	}

	/deep/ .el-dialog__header {
		display: flex;
		background-color: #97233F;
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 24px;
		text-transform: uppercase;

		.el-dialog__title,
		.el-icon-close {
			color: #FFF !important;
			text-transform: uppercase;
		}
	}

	.popcon {
		display: flex;
		width: 100%;
		font-family: SourceHanSansCN-Bold, sans-serif;
		font-size: 18px;
		font-weight: bold;
		flex-wrap: wrap;
		justify-content: space-between;
		text-align: center;

		div {
			width: 45.63%;
			height: 70px;
			line-height: 70px;
			border-radius: 8px;
			box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.1);
			color: #97233F;
			margin-bottom: 20px;
		}
	}

	.bgline {
		background: rgba(181, 161, 145, 0.5) !important;
		color: #97233F !important;
		padding: 5px 20px;
		display: block;
		font-size: 20px;
		margin-top: 10px;
		// width: 85%;
	}
</style>